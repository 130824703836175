













































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Brand, BrandIndexItem, BrandQueryResultItem } from '@/api/braendz';

import HorizontalBrandTile from './HorizontalBrandTile.vue';

@Component({
    components: {
        HorizontalBrandTile
    }
})
export default class HorizontalBrandTileGrid extends Vue {
    public isBrandQueryResultItem = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is BrandQueryResultItem => {
        return (b as BrandQueryResultItem)?.indexItem !== undefined;
    }
    public isBrandIndexItem = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is BrandIndexItem => {
        return (b as BrandIndexItem)?.brandId !== undefined;
    }
    public isBrand = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is Brand => {
        return !this.isBrandQueryResultItem(b) && !this.isBrandIndexItem(b);
    }

    // Component Properties:
    @Prop({ required: true })
    public brands!: (BrandQueryResultItem | BrandIndexItem | Brand)[];

    // Getter:
    public get includedBrands(): (BrandQueryResultItem | BrandIndexItem | Brand)[] {
        return this.brands.filter(i => !this.isExcluded(i));
    }

    public get excludedBrands(): (BrandQueryResultItem | BrandIndexItem | Brand)[] {
        return this.brands.filter(i => this.isExcluded(i));
    }

    // Methods:
    public getIdOrIndex(item: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined, index: number) {
        if(item) {
            if(this.isBrandQueryResultItem(item)) return item.indexItem?.id ?? item.indexItem?.brandId ?? index;
            if(this.isBrandIndexItem(item)) return item.id ?? item.brandId ?? index;
            if(this.isBrand(item)) return item.id ?? index;
        }
        return index;
    }

    public isExcluded(brand: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined): boolean {
        if(!brand) {
            return false;
        }

        if(this.isBrandQueryResultItem(brand) && brand.indexItem?.brandId) return this.$store.getters.isBrandExcluded(brand.indexItem.brandId);
        if(this.isBrandIndexItem(brand) && brand.brandId) return this.$store.getters.isBrandExcluded(brand.brandId);
        if(this.isBrand(brand) && brand.id) return this.$store.getters.isBrandExcluded(brand.id);

        return false;
    }
}
