



















































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Brand, BrandIndexItem, MasterDataItem } from '@/api/braendz';
import { dummyBrand } from '@/models/DummyBrand'
import { AccountInfo } from "@azure/msal-browser";

import HiddenBrandOverlay from '@/components/Brands/HiddenBrandOverlay.vue';
import BrandDetailsPopup from '@/components/Brands/BrandDetailsPopup.vue';
import SaveBrandBookmarkPopup from '@/components/BrandBookmarks/SaveBrandBookmarkPopup.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import TruncateTooltip from '@/components/TruncateTooltip.vue';
import { brandStateCategoryColors, brandStateCategoryIcons } from '@/models/BrandStateCategories';

@Component({
    components: {
        HiddenBrandOverlay,
        BrandDetailsPopup,
        SaveBrandBookmarkPopup,
        ConfirmationPopup,
        TruncateTooltip
    }})
export default class HorizontalBrandTile extends Vue {
    public saveBookmarkPopupVisible = false;
    public deleteBookmarkConfirmationPopupVisible = false;

    // Component Properties:
    @Prop({ required: true })
    public brand!: BrandIndexItem | Brand | null | undefined;

    @Prop({ required: false })
    public loading?: boolean;

    @Prop({ required: false })
    public brandBookmarkContainerId?: number;

    // Getter
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    public get isExcluded(): boolean {
        return this.brandId && this.$store.getters.isBrandExcluded(this.brandId);
    }

    public get brandOrDummy(): BrandIndexItem | Brand {
        return this.brand ? this.brand : dummyBrand;
    }

    public get brandId(): string | null {
        if(!this.brand) {
            return null;
        }

        if("brandId" in this.brand && this.brand.brandId) {
            return this.brand.brandId;
        }
        else if(this.brand.id) {
            return this.brand.id;
        }

        return null;
    }

    public get brandStateCategory(): MasterDataItem | null {
        if(!this.brand) {
            return null;
        }

        if("brandStateCategory" in this.brand && this.brand.brandStateCategory) {
            return this.brand.brandStateCategory;
        }

        return null;
    }

    public get brandStateCategoryColor(): string | undefined {
        return brandStateCategoryColors.find(i => i.key === this.brandStateCategory?.key)?.color;
    }

    public get brandStateCategoryIcon(): string | undefined {
        return brandStateCategoryIcons.find(i => i.key === this.brandStateCategory?.key)?.icon;
    }

    public get hidden() {
        return !this.brand;
    }

    public get fullBrandLogoUrl(): string | null {
        if(this.hidden) {
            return null;
        }
        return this.$braendz.getBrandLogoUrl(this.brandOrDummy);
    }

    // Methods:
    public bookmarkBrand(): void {
        if(this.brandId) {
            this.saveBookmarkPopupVisible = true;
        }
    }

    public removeBookmark(): void {
        if(this.brandBookmarkContainerId && this.brandId) {
            this.$store.dispatch('removeBrandBookmark', { containerId: this.brandBookmarkContainerId, brandId: this.brandId })
        }
    }

    public toggleExclusion(): void {
        if(this.brandId) {
            if(this.isExcluded) {
                this.$store.dispatch("removeBrandExclusion", this.brandId);
            }
            else {
                this.$store.dispatch("addBrandExclusion", this.brandId);
            }
        }
    }
}
